var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-registration-fee-costs"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t('event.modules.registrationModule.feeCosts.sum')))]),_c('th',[_vm._v(_vm._s(_vm.$t('event.modules.registrationModule.feeCosts.validSince')))]),_c('th',[_vm._v(_vm._s(_vm.$t('event.modules.registrationModule.feeCosts.validUntil')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.dateOfPayment')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.message')))]),_c('th')])]),_c('tbody',_vm._l((_vm.value),function(item,$index){return _c('tr',{key:$index},[_c('td',[_vm._v(_vm._s($index + 1))]),_c('td',[_c('v-text-field',{attrs:{"type":"number","min":0},model:{value:(_vm.value[$index].price),callback:function ($$v) {_vm.$set(_vm.value[$index], "price", _vm._n($$v))},expression:"value[$index].price"}})],1),_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.value[$index].startDate),callback:function ($$v) {_vm.$set(_vm.value[$index], "startDate", $$v)},expression:"value[$index].startDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.state.item[$index].startDateClose),callback:function ($$v) {_vm.$set(_vm.state.item[$index], "startDateClose", $$v)},expression:"state.item[$index].startDateClose"}},[_c('v-date-picker',{on:{"input":function($event){_vm.state.item[$index].startDateClose = false}},model:{value:(_vm.value[$index].startDate),callback:function ($$v) {_vm.$set(_vm.value[$index], "startDate", $$v)},expression:"value[$index].startDate"}})],1)],1),_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.value[$index].endDate),callback:function ($$v) {_vm.$set(_vm.value[$index], "endDate", $$v)},expression:"value[$index].endDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.state.item[$index].endDateClose),callback:function ($$v) {_vm.$set(_vm.state.item[$index], "endDateClose", $$v)},expression:"state.item[$index].endDateClose"}},[_c('v-date-picker',{attrs:{"min":_vm.value[$index].startDate},on:{"input":function($event){_vm.state.item[$index].endDateClose = false}},model:{value:(_vm.value[$index].endDate),callback:function ($$v) {_vm.$set(_vm.value[$index], "endDate", $$v)},expression:"value[$index].endDate"}})],1)],1),_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","persistent-hint":_vm.value[$index].price > 0,"clearable":""},model:{value:(_vm.value[$index].paymentDate),callback:function ($$v) {_vm.$set(_vm.value[$index], "paymentDate", $$v)},expression:"value[$index].paymentDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.state.item[$index].paymentDateClose),callback:function ($$v) {_vm.$set(_vm.state.item[$index], "paymentDateClose", $$v)},expression:"state.item[$index].paymentDateClose"}},[_c('v-date-picker',{attrs:{"min":_vm.value[$index].endDate},on:{"input":function($event){_vm.state.item[$index].paymentDateClose = false}},model:{value:(_vm.value[$index].paymentDate),callback:function ($$v) {_vm.$set(_vm.value[$index], "paymentDate", $$v)},expression:"value[$index].paymentDate"}})],1)],1),_c('td',[_c('v-text-field',{model:{value:(_vm.value[$index].message),callback:function ($$v) {_vm.$set(_vm.value[$index], "message", $$v)},expression:"value[$index].message"}})],1),_c('td',[_c('v-btn',{attrs:{"icon":"","disabled":_vm.value.length <= 1},on:{"click":function($event){return _vm.removeItem($index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])}),0),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.addItem}},[_vm._v(_vm._s(_vm.$t('event.modules.registrationModule.feeCosts.addNewAmount'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1)])])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }