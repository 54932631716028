






























































































import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props, { root }) {
    const state = reactive({
      item: [
        {
          startDateClose: false,
          endDateClose: false,
          paymentDateClose: false,
        },
      ],
    });

    const addItem = () => {
      const index = props.value.length;
      props.value.push({
        order: index,
        price: null,
        startDate: null,
        endDate: null,
        paymentDate: null,
        message: root.$tc(
          "event.modules.registrationModule.feeCosts.cancelRegistrationAfterDate"
        ),
      });
      state.item.push({
        startDateClose: false,
        endDateClose: false,
        paymentDateClose: false,
      });
    };

    const removeItem = (index: number) => {
      props.value.splice(index, 1);
    };

    const rules = {
      required: [(v: any) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { state, addItem, removeItem, rules };
  },
});
